/**
 * 文件描述: 日志Api文档
 * @author 阿苏
 * @dTime  2022/09/17
 * @see    
 */
import feach from "../axios";
const APIS = {
  LogList: "/systemLog/list", // 获取菜单导航
  LogType: "/systemLog/logTypeList", // 获取日志操作类型
}
/**
 * @description 获取日志列表数据
 * @param 
 */
export const getLogList = params => {
  return feach.request({
    url: APIS.LogList,
    method: "GET",
    params
  });
};
/**
 * @description 获取日志操作类型
 * @param 
 */
export const getLogType = params => {
  return feach.request({
    url: APIS.LogType,
    method: "GET",
    params
  });
};