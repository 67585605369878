<!--  -->
<template>
  <div class='log-list'>
    <div class="active">
      <div class="active-info">
        <div class="item-info">
          <p class="lable">操作人：</p>
          <el-input v-model="operator" clearable placeholder="请输入任务名称" @change="searchData"><i slot="prefix"
              class="el-input__icon el-icon-search" @click="searchData"></i></el-input>
        </div>
        <div class="item-info">
          <p class="lable">操作类型：</p>
          <el-select v-model="operationType" placeholder="活动区域" @change="searchData()" clearable>
            <el-option v-for="(item,index) in logType" :key="index" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="item-info">
          <p class="lable">操作时间：</p>
          <el-date-picker v-model="operationTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" @change="changeDate">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="content">
      <TableModule :tableData="tableData" :page="true" :total="total" @pageChange="pageChange" :pageNum="pages">
        <el-table-column prop="operatePage" label="操作模块" align="center">
        </el-table-column>
        <el-table-column prop="logType" label="操作类型" align="center">
        </el-table-column>
        <el-table-column prop="operateParam" label="操作参数" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间" align="center">
        </el-table-column>
        <el-table-column prop="createBy" label="操作人" align="center">
        </el-table-column>
      </TableModule>
    </div>
  </div>
</template>

<script>
import TableModule from "../../components/Table/TableModule.vue";
import * as Apis from "../../http/api/log"
export default {
  name: '',
  //import引入的组件需要注入到对象中才能使用
  components: {
    TableModule
  },
  data () {
    return {
      operator: "",
      operationTime: [],
      operationType: "",
      logType: [],
      tableData: [],
      startTime: "",
      endTime: "",
      total: 0,
      pages: 1
    };
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    /**
     * @description 获取日志列表
     * @param 
     */
    async getLogList () {
      try {
        let { code, body } = await Apis.getLogList({
          pages: this.pages,
          rows: 10,
          createBy: this.operator,
          logType: this.operationType,
          startTime: this.startTime,
          endTime: this.endTime
        })
        if (code == 200) {
          this.tableData = body.content
          this.total = body.total
        }
      } catch (error) {
        console.error('getLogList', error);
      }
    },
    /**
     * @description 获取日志类型
     * @param 
     */
    async getLogType () {
      try {
        let { code, body } = await Apis.getLogType()
        if (code == 200) {
          this.logType = body
        }
      } catch (error) {
        console.error('getLogType', error);
      }
    },
    /**
     * @description 根据条件搜索列表
     * @param 
     */
    searchData () {
      this.pages = 1
      this.getLogList()
    },
    changeDate () {
      this.startTime = this.operationTime[0]
      this.endTime = this.operationTime[1]
      this.searchData()
    },
    /**
     * @description 分页
     * @param 
     */
    pageChange (val) {
      this.pages = val
      this.getLogList()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getLogList()
    this.getLogType()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },

}
</script>
<style lang="scss" scoped>
.log-list {
  padding: 30px 24px;
}

.active {
  display: flex;
  margin-bottom: 30px;
}

.active-info {
  display: flex;
  align-items: center;

  .item-info {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .lable {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      white-space: nowrap;
    }
  }
}
</style>